import {
  SdConsumerCallbackActions,
  SetInputValidated,
  SET_INPUT_SD_CONSUMER_CALL_BACK,
  SET_FORM_LOADING_SD_CONSUMER_CALL_BACK,
  SetFormLoading,
  SET_PHONE_INPUT_SD_CONSUMER_CALL_BACK,
  SetPhoneInputValidated,
  SET_FORM_SUCCESS_SD_CONSUMER_CALL_BACK,
  SetFormSuccess,
  SET_GENERAL_ERROR_SD_CONSUMER_CALL_BACK,
  SetGeneralErrorMessage,
  RESET_FORM_SD_CONSUMER_CALL_BACK,
} from './actions';

export type SdConsumerCallbackData = {
  isLoading: boolean;
  isSuccess: boolean;
  generalError: {
    message?: string;
  };
  recaptcha: {
    value: string;
    error: string;
  };
  name: {
    value: string;
    error: string;
  };
  phoneNumber: {
    countryCode: string;
    number: string;
    error: string;
  };
  orderNumbers: { // separated by commas
    value: string;
    error: string;
  }
  reason: {
    value: string;
    error: string;
  };
}

export const initialState: SdConsumerCallbackData = {
  isLoading: false,
  isSuccess: false,
  generalError: {
    message: '',
  },
  recaptcha: {
    value: '',
    error: '',
  },
  name: {
    value: '',
    error: '',
  },
  phoneNumber: {
    countryCode: '+971',
    number: '',
    error: '',
  },
  orderNumbers: {
    value: '',
    error: '',
  },
  reason: {
    value: '',
    error: '',
  },
};

const SdConsumerCallback = (
  state: SdConsumerCallbackData = initialState,
  action: SdConsumerCallbackActions,
): SdConsumerCallbackData => {
  switch (action.type) {
    case SET_INPUT_SD_CONSUMER_CALL_BACK:
      return {
        ...state,
        ...(action as SetInputValidated).payload,
      };
    case SET_PHONE_INPUT_SD_CONSUMER_CALL_BACK:
      return {
        ...state,
        ...(action as SetPhoneInputValidated).payload,
      };
    case SET_FORM_LOADING_SD_CONSUMER_CALL_BACK: {
      return {
        ...state,
        isLoading: (action as SetFormLoading).payload.isLoading,
      };
    }
    case SET_FORM_SUCCESS_SD_CONSUMER_CALL_BACK: {
      return {
        ...state,
        isSuccess: (action as SetFormSuccess).payload.isSuccess,
      };
    }
    case SET_GENERAL_ERROR_SD_CONSUMER_CALL_BACK: {
      return {
        ...state,
        generalError: {
          message: (action as SetGeneralErrorMessage).payload,
        },
      };
    }
    case RESET_FORM_SD_CONSUMER_CALL_BACK: {
      return initialState;
    }
    default:
      return state;
  }
};

export default SdConsumerCallback;
